import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un gran capell blan de fins a 18 cm de diàmetre convex i un poc deprimit al centre de coloració variada, de blanc terrós a gris cendra, dominant sovint els color rosats. El marge és ondulat, sobretot als exemplars adults. Davall el capell es troben tubs curts i els porus són petits, angulosos, primer grocs però aviat es tornen blaus amb la pressió dels dits. El peu dur i compacte és més prim a la base i cap a dalt, engruixit cap enmig. Aquest és de color groc més o manco clar, passant amb el temps a color marronenc, tornant blau si el tocam. Les espores són marró olivàcies en massa, el·lipsoidals, llises, d’11-16 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      